import { fadeColor } from '../utils';

const primarySolid = {
    4: '#F6F7FF',
    8: '#ECEFFF',
    10: '#EDEFFB',
    12: '#E3E7FF',
    20: '#D0D8FF',
    60: '#7389FF',
    70: '#5671FF',
    100: '#153BFF',
};

const primaryAlpha = fadeColor(primarySolid[100], 5, 8, 12, 16, 20, 40, 70);

const primaryCustom = {
    dark: '#0428D1',
};

const blackSolid = {
    3: '#F8F8FA',
    4: '#F5F6F8',
    8: '#EBEDF1',
    20: '#CDD2DC',
    70: '#506283',
    80: '#374C71',
    90: '#1E3560',
    100: '#051F4E',
};

const blackCustom = {
    dark: '#031638',
};

const blackCustomDarkAlpha = fadeColor(blackCustom.dark, 80);

const blackAlpha = fadeColor(blackSolid[100], 3, 4, 5, 6, 8, 10, 12, 16, 20, 30, 60, 70);

const whiteSolid = {
    100: '#FFFFFF',
};

const whiteAlpha = fadeColor(whiteSolid[100], 8, 20, 40, 60, 70);

const negativeSolid = {
    4: '#FFF7F7',
    12: '#FFE7E7',
    100: '#FF3333',
};

const negativeAlpha = fadeColor(negativeSolid[100], 5, 8, 12, 16, 40, 70);

const negativeCustom = {
    dark: '#E50000',
};

const positiveSolid = {
    4: '#F7FDF8',
    12: '#E8F9EA',
    60: '#8CDF98',
    100: '#3FC953',
};

const positiveAlpha = fadeColor(positiveSolid[100], 7, 12, 24, 40, 70);

const positiveCustom = {
    dark: '#2CBE55',
    extradark: '#1F843B',
};

const infoSolid = {
    4: '#FFFBF6',
    12: '#FFF3E4',
    80: '#FFAC4A',
    100: '#FF971D',
};

const infoAlpha = fadeColor(infoSolid[100], 7, 12, 24, 40, 70);

const infoCustom = {
    dark: '#F38401',
    extradark: '#B25C01',
};

const purpleSolid = {
    4: '#FBF5FD',
    10: '#F5E6FA',
    12: '#F3E0F9',
    100: '#9900CC',
};

const purpleAlpha = fadeColor(purpleSolid[100], 5, 8, 10, 12, 16, 20);

const purpleCustom = {
    dark: '#8200AD',
};

const pinkSolid = {
    4: '#FFF7FD',
    12: '#FFE7F9',
    100: '#FF33CC',
};

const pinkAlpha = fadeColor(pinkSolid[100], 5, 16);

const pinkCustom = {
    dark: '#E000A8',
};

const darkblueSolid = {
    4: '#F6F7FB',
    12: '#E4E6F2',
    100: '#1D3295',
};

const darkblueAlpha = fadeColor(darkblueSolid[100], 5, 20);

const darkblueCustom = {
    dark: '#10206B',
};

const peachCustom = {
    light: '#FF8839',
    dark: '#FF5E82',
};

const goldCustom = {
    light: '#FFD479',
    dark: '#FFAE35',
};

const slateGraySolid = {
    100: '#434A50',
};

export const colorsPalette = {
    primarySolid,
    primaryAlpha,
    primaryCustom,
    blackSolid,
    blackAlpha,
    blackCustom,
    blackCustomDarkAlpha,
    whiteSolid,
    whiteAlpha,
    negativeSolid,
    negativeAlpha,
    negativeCustom,
    peachCustom,
    positiveSolid,
    positiveAlpha,
    positiveCustom,
    infoSolid,
    infoAlpha,
    infoCustom,
    purpleSolid,
    purpleAlpha,
    purpleCustom,
    pinkSolid,
    pinkAlpha,
    pinkCustom,
    darkblueSolid,
    darkblueAlpha,
    darkblueCustom,
    goldCustom,
    slateGraySolid,
} as const;
