type SectionName = 'english' | 'german' | 'spanish' | 'french' | 'italian';

// NOTE: Don't edit this file directly. Read FRONTEND.md guidelines for more details
export interface Urls {
    (name: 'about_us', locale?: string): string;
    (name: 'affiliate_program', locale?: string): string;
    (name: 'api_class_questionnaire_info', params: { name: string }): string;
    (name: 'api_class_questionnaire_onboarding_survey'): string;
    (name: 'api_classes_appointments_create', params: { uniqueId: string }): string;
    (name: 'api_feature_flags_decide_on_demand'): string;
    (name: 'api_feedback_create'): string;
    (name: 'api_lesson_show', params: { id: number }): string;
    (name: 'api_login_check'): string;
    (name: 'api_missed_class_feedback_create'): string;
    (name: 'api_modules_index'): string;
    (name: 'api_oauth_connected_accounts'): string;
    (name: 'api_oauth_resource_owners'): string;
    (name: 'api_payment_contract_delete', params: { reference: string }): string;
    (name: 'api_payments_methods', params: { currency?: string }): string;
    (name: 'api_payments_purchase'): string;
    (name: 'api_payments_subscription_create'): string;
    (name: 'api_product_variant_index'): string;
    (name: 'api_sections_index'): string;
    (name: 'api_start_date_parameter'): string;
    (name: 'api_students_appointments_list', params: { student_id: number }): string;
    (name: 'api_students_cefr_pdf', params: { id: string, moduleId: string }): string;
    (name: 'api_students_classes_create', params: { student_id: number }): string;
    (name: 'api_students_classes_pdf', params: { id: string }): string;
    (name: 'api_students_learningunits_index', params: { student_id: number }): string;
    (name: 'api_students_modules_index', params: { student_id: number }): string;
    (name: 'api_students_starting_modules_index', params: { student_id: number }): string;
    (name: 'api_students_subscriptions_list', params: { student_id: number }): string;
    (name: 'api_students_update', params: { id: number }): string;
    (name: 'api_students_update_password', params: { id: number }): string;
    (name: 'api_students_update_preferences', params: { id: number }): string;
    (name: 'api_subscription_cancel', params: { id: number }): string;
    (name: 'api_subscription_change'): string;
    (name: 'api_subscription_delete_next'): string;
    (name: 'api_subscription_uncancel', params: { id: number }): string;
    (name: 'api_subscription_unpause', params: { id: number }): string;
    (name: 'api_transactions_show_last'): string;
    (name: 'api_users_add_agreement'): string;
    (name: 'api_users_add_scheduled_class'): string;
    (name: 'api_users_create'): string;
    (name: 'api_users_invoice_address'): string;
    (name: 'api_users_invoices_list', params: { user_id: number }): string;
    (name: 'api_users_me'): string;
    (name: 'api_users_profile'): string;
    (name: 'api_users_resend_confirmation'): string;
    (name: 'api_users_reset_password'): string;
    (name: 'api_users_timezone'): string;
    (name: 'api_users_update', params: { id: number }): string;
    (name: 'api_users_update_forgotten_password', params: { token: string }): string;
    (name: 'api_users_update_locale'): string;
    (name: 'api_users_update_password'): string;
    (name: 'api_users_withdraw_agreement'): string;
    (name: 'become_a_teacher', locale?: string): string;
    (name: 'booking_preview', locale?: string): string;
    (name: 'business_english', locale?: string): string;
    (name: 'careers', locale?: string): string;
    (name: 'cefr', locale?: string): string;
    (name: 'complete_account_set_password', locale?: string): string;
    (name: 'complete_account_setup', locale?: string): string;
    (name: 'corporate', locale?: string): string;
    (name: 'direct_register_section', params: { section: string | SectionName, curriculum: string }, locale?: string): string;
    (name: 'english', locale?: string): string;
    (name: 'error_language', params: { section: string | SectionName }, locale?: string): string;
    (name: 'faq', locale?: string): string;
    (name: 'forgot_password', locale?: string): string;
    (name: 'forgot_password_successful', locale?: string): string;
    (name: 'free_trial_purchase', params: { packageId: string }, locale?: string): string;
    (name: 'french', locale?: string): string;
    (name: 'german', locale?: string): string;
    (name: 'gesdinet_jwt_refresh_token'): string;
    (name: 'get_started', locale?: string): string;
    (name: 'get_started_step_name', params: { stepName: string }, locale?: string): string;
    (name: 'how_it_works', locale?: string): string;
    (name: 'hwi_oauth_connect_login_service', params: { service: string, key: string }): string;
    (name: 'hwi_oauth_connect_registration', params: { key: string }): string;
    (name: 'hwi_oauth_connect_userinfo', params: { key: string }): string;
    (name: 'hwi_oauth_disconnect_service', params: { service: string }): string;
    (name: 'hwi_oauth_finalize_disconnect_service', params: { service: string, token: string }): string;
    (name: 'imprint', locale?: string): string;
    (name: 'italian', locale?: string): string;
    (name: 'lingoda.student.booking', locale?: string): string;
    (name: 'lingoda.student.challenge', params: { challengeName: string }, locale?: string): string;
    (name: 'lingoda.student.changeLearningLanguageStorefront', params: { section: string, curriculum: string }, locale?: string): string;
    (name: 'lingoda.student.changeLearningLanguageSuccess', locale?: string): string;
    (name: 'lingoda.student.classDetails', params: { class_id: string }, locale?: string): string;
    (name: 'lingoda.student.classes', locale?: string): string;
    (name: 'lingoda.student.courseOverview', params: { moduleName?: string }, locale?: string): string;
    (name: 'lingoda.student.creditsConversion', locale?: string): string;
    (name: 'lingoda.student.leadConversionFlow', locale?: string): string;
    (name: 'lingoda.student.lessonDetails', params: { lessonId: string }, locale?: string): string;
    (name: 'lingoda.student.preClassChecklist', params: { classId: string }, locale?: string): string;
    (name: 'lingoda.student.settings', params: { page?: string }, locale?: string): string;
    (name: 'lingoda.student.store', locale?: string): string;
    (name: 'lingoda.student.store.accountClosed', locale?: string): string;
    (name: 'lingoda.student.store.cancelCourseChange', params: { subscription: string, cancelReason: string }, locale?: string): string;
    (name: 'lingoda.student.store.cancelCoursePause', params: { subscription: string, cancelReason: string }, locale?: string): string;
    (name: 'lingoda.student.store.cancelSurvey', params: { subscription: string }, locale?: string): string;
    (name: 'lingoda.student.store.canceledCourse', params: { subscription: string }, locale?: string): string;
    (name: 'lingoda.student.store.changedCourse', locale?: string): string;
    (name: 'lingoda.student.store.changedPaymentMethod', locale?: string): string;
    (name: 'lingoda.student.store.confirmCancel', params: { subscription: string, cancelReason: string }, locale?: string): string;
    (name: 'lingoda.student.store.creditsAdded', locale?: string): string;
    (name: 'lingoda.student.store.reactivateCourse', locale?: string): string;
    (name: 'lingoda.student.store.reactivated', locale?: string): string;
    (name: 'lingoda.student.store.refusedPayment', params: { subscription: string }, locale?: string): string;
    (name: 'lingoda.student.subscriptionsStore', locale?: string): string;
    (name: 'lingoda.student.teacher', params: { uid: string }, locale?: string): string;
    (name: 'linguaskill_from_cambridge', locale?: string): string;
    (name: 'm2m_register_section', params: { section: string | SectionName }, locale?: string): string;
    (name: 'm2m_signup_section', params: { section: string | SectionName }, locale?: string): string;
    (name: 'package_purchase', params: { packageId: string }, locale?: string): string;
    (name: 'payment_method_change_default'): string;
    (name: 'payment_method_create'): string;
    (name: 'press', locale?: string): string;
    (name: 'privacy', locale?: string): string;
    (name: 'product_order', params: { productVariantId: number }, locale?: string): string;
    (name: 'product_purchase', params: { productVariantId: number }, locale?: string): string;
    (name: 'referral', params: { code: string }, locale?: string): string;
    (name: 'register', locale?: string): string;
    (name: 'register_sprint_section', params: { section: string | SectionName, curriculum: string }, locale?: string): string;
    (name: 'register_teams_section', params: { section: string | SectionName }, locale?: string): string;
    (name: 'research', locale?: string): string;
    (name: 'resolve_classroom_url', params: { uniqueId: string }): string;
    (name: 'resources', locale?: string): string;
    (name: 'section_home', params: { section: string | SectionName }, locale?: string): string;
    (name: 'social_initiate_disconnect_service', params: { service: string, token: string }, locale?: string): string;
    (name: 'social_login_connect_confirmed', locale?: string): string;
    (name: 'social_login_connect_confirmed_mobile', params: { token: string }, locale?: string): string;
    (name: 'social_register', params: { key: string }, locale?: string): string;
    (name: 'social_register_confirm', params: { section: string }, locale?: string): string;
    (name: 'social_register_confirmed_mobile', params: { token: string }, locale?: string): string;
    (name: 'spanish', locale?: string): string;
    (name: 'sprint_package_purchase', params: { packageId: string }, locale?: string): string;
    (name: 'sprint_signup_section', params: { section: string | SectionName, curriculum: string }, locale?: string): string;
    (name: 'sprint_student_store_checkout', params: { purchaseId: string }, locale?: string): string;
    (name: 'student_achievements', locale?: string): string;
    (name: 'student_change_subscription_failed', locale?: string): string;
    (name: 'student_email_verification', locale?: string): string;
    (name: 'student_exercises', params: { learningUnitId: number }, locale?: string): string;
    (name: 'student_flashcards', params: { learningUnitId: number }, locale?: string): string;
    (name: 'student_grammar', params: { lessonId: number }, locale?: string): string;
    (name: 'student_grammar_item', params: { grammarId: string }, locale?: string): string;
    (name: 'student_home', locale?: string): string;
    (name: 'student_homework', params: { lessonId: number }, locale?: string): string;
    (name: 'student_lemonade', params: { id: number }, locale?: string): string;
    (name: 'student_logout', locale?: string): string;
    (name: 'student_onboarding', locale?: string): string;
    (name: 'student_onboarding_booking', locale?: string): string;
    (name: 'student_onboarding_booking_skip', locale?: string): string;
    (name: 'student_onboarding_congratulations', locale?: string): string;
    (name: 'student_onboarding_level', locale?: string): string;
    (name: 'student_onboarding_level_pick', locale?: string): string;
    (name: 'student_onboarding_level_placed', locale?: string): string;
    (name: 'student_onboarding_level_sub', params: { level: number, sublevel: number }, locale?: string): string;
    (name: 'student_onboarding_survey', locale?: string): string;
    (name: 'student_onboarding_timezone', locale?: string): string;
    (name: 'student_onboarding_welcome', locale?: string): string;
    (name: 'student_post_checkout', locale?: string): string;
    (name: 'student_post_checkout_book_class', locale?: string): string;
    (name: 'student_post_checkout_congratulations', locale?: string): string;
    (name: 'student_post_checkout_credits_type_selection', locale?: string): string;
    (name: 'student_post_checkout_proficiency', locale?: string): string;
    (name: 'student_post_checkout_proficiency_find_your_level', locale?: string): string;
    (name: 'student_post_checkout_proficiency_level', locale?: string): string;
    (name: 'student_post_checkout_proficiency_sub_level', params: { level: number }, locale?: string): string;
    (name: 'student_post_checkout_proficiency_summary', params: { level: number, sublevel: number }, locale?: string): string;
    (name: 'student_post_checkout_welcome', locale?: string): string;
    (name: 'student_practice', locale?: string): string;
    (name: 'student_practice_sequence', params: { lessonId: number }, locale?: string): string;
    (name: 'student_profile', locale?: string): string;
    (name: 'student_quiz', params: { lessonId: number }, locale?: string): string;
    (name: 'student_quiz_next', locale?: string): string;
    (name: 'student_store_credits_checkout', params: { purchaseId: string }, locale?: string): string;
    (name: 'student_store_m2m_checkout', params: { purchaseId: string }, locale?: string): string;
    (name: 'student_store_paymentFailed', params: { purchaseId?: string }, locale?: string): string;
    (name: 'student_store_paymentPending', params: { purchaseId?: string }, locale?: string): string;
    (name: 'student_vocabulary', params: { lessonId: number }, locale?: string): string;
    (name: 'teacher_booking', locale?: string): string;
    (name: 'teacher_booking_pagination', params: { startDate: string }, locale?: string): string;
    (name: 'teacher_class', params: { classId: string }, locale?: string): string;
    (name: 'teacher_forgot_password', locale?: string): string;
    (name: 'teacher_forgot_password_success', locale?: string): string;
    (name: 'teacher_home', locale?: string): string;
    (name: 'teacher_jwt_logout', locale?: string): string;
    (name: 'teacher_learning_material', locale?: string): string;
    (name: 'teacher_log_and_payments', locale?: string): string;
    (name: 'teacher_login', locale?: string): string;
    (name: 'teacher_login_check'): string;
    (name: 'teacher_open_pdf_uid', params: { uniqueId: string }): string;
    (name: 'teacher_profile_new', params: { page: string }, locale?: string): string;
    (name: 'teacher_reset_password', params: { token: string }, locale?: string): string;
    (name: 'teacher_terms', params: { agreementId: string }, locale?: string): string;
    (name: 'teacher_view_terms', params: { agreementId: string }, locale?: string): string;
    (name: 'teams_package_purchase', params: { packageId: string }, locale?: string): string;
    (name: 'teams_pricing', locale?: string): string;
    (name: 'teams_signup_section', params: { section: string | SectionName }, locale?: string): string;
    (name: 'teams_store_checkout', params: { purchaseId: string }, locale?: string): string;
    (name: 'terms', locale?: string): string;
    (name: 'user_resetting_reset', params: { token: string }, locale?: string): string;
    (name: 'user_security_check'): string;
    (name: 'user_security_jwt_logout'): string;
    (name: 'user_security_login', locale?: string): string;
    (name: 'user_set_password_for_new_account', params: { token: string }, locale?: string): string;
    (name: 'unknown route'): string;
}

export default {
    about_us: {path: '/:locale/about-us'},
    affiliate_program: {path: '/:locale/content/affiliate-program'},
    api_class_questionnaire_info: {path: '/api/questionnaire/:name'},
    api_class_questionnaire_onboarding_survey: {path: '/api/questionnaire/onboarding-survey'},
    api_classes_appointments_create: {path: '/api/classes/:uniqueId/appointments'},
    api_feature_flags_decide_on_demand: {path: '/api/feature-flag/decide-on-demand'},
    api_feedback_create: {path: '/api/feedbacks'},
    api_lesson_show: {path: '/api/lessons/:id'},
    api_login_check: {path: '/api/login_check'},
    api_missed_class_feedback_create: {path: '/api/class-feedback/missed-class'},
    api_modules_index: {path: '/api/modules'},
    api_oauth_connected_accounts: {path: '/api/oauth/user/connected-accounts'},
    api_oauth_resource_owners: {path: '/api/oauth/resource-owners'},
    api_payment_contract_delete: {path: '/api/payment-contracts/:reference'},
    api_payments_methods: {path: '/api/payments/methods/:currency'},
    api_payments_purchase: {path: '/api/payments/purchase'},
    api_payments_subscription_create: {path: '/api/payments/subscription'},
    api_product_variant_index: {path: '/api/product-variants'},
    api_sections_index: {path: '/api/sections'},
    api_start_date_parameter: {path: '/api/class-start-date'},
    api_students_appointments_list: {path: '/api/students/:student_id/appointments'},
    api_students_cefr_pdf: {path: '/api/students/:id/:moduleId/cefr.pdf'},
    api_students_classes_create: {path: '/api/students/:student_id/classes'},
    api_students_classes_pdf: {path: '/api/students/:id/attended-classes.pdf'},
    api_students_learningunits_index: {path: '/api/students/:student_id/learning-units'},
    api_students_modules_index: {path: '/api/students/:student_id/modules'},
    api_students_starting_modules_index: {path: '/api/students/:student_id/starting_modules'},
    api_students_subscriptions_list: {path: '/api/students/:student_id/subscriptions'},
    api_students_update: {path: '/api/students/:id'},
    api_students_update_password: {path: '/api/students/:id/password'},
    api_students_update_preferences: {path: '/api/students/:id/preferences'},
    api_subscription_cancel: {path: '/api/subscriptions/:id/cancel'},
    api_subscription_change: {path: '/api/subscriptions/change'},
    api_subscription_delete_next: {path: '/api/subscriptions/next'},
    api_subscription_uncancel: {path: '/api/subscriptions/:id/uncancel'},
    api_subscription_unpause: {path: '/api/subscriptions/:id/unpause'},
    api_transactions_show_last: {path: '/api/transactions/last'},
    api_users_add_agreement: {path: '/api/users/add/agreement'},
    api_users_add_scheduled_class: {path: '/api/users/scheduled-class'},
    api_users_create: {path: '/api/users'},
    api_users_invoice_address: {path: '/api/users/invoice-address'},
    api_users_invoices_list: {path: '/api/users/:user_id/student-invoices'},
    api_users_me: {path: '/api/users/me'},
    api_users_profile: {path: '/api/users/profile'},
    api_users_resend_confirmation: {path: '/api/users/resend-confirmation'},
    api_users_reset_password: {path: '/api/users/reset-password'},
    api_users_timezone: {path: '/api/users/timezone'},
    api_users_update: {path: '/api/users/:id'},
    api_users_update_forgotten_password: {path: '/api/users/reset/:token'},
    api_users_update_locale: {path: '/api/users/locale'},
    api_users_update_password: {path: '/api/users/update-password'},
    api_users_withdraw_agreement: {path: '/api/users/withdraw/agreement'},
    become_a_teacher: {path: '/:locale/become-a-teacher'},
    booking_preview: {path: '/:locale/booking/preview'},
    business_english: {
        local: {
            de: {path: '/:locale/business-englisch'},
            es: {path: '/:locale/ingles-de-negocios'},
            fr: {path: '/:locale/anglais-des-affaires'},
            it: {path: '/:locale/business-english-it'},
            pl: {path: '/:locale/business-english-pl'},
            pt_BR: {path: '/:locale/business-english-br'},
            tr: {path: '/:locale/business-english-tr'},
        },
        path: '/:locale/business-english',
    },
    careers: {path: '/:locale/p/careers'},
    cefr: {path: '/:locale/cefr'},
    complete_account_set_password: {path: '/:locale/complete-account-set-password'},
    complete_account_setup: {path: '/:locale/complete-account-setup'},
    corporate: {
        local: {
            de: {path: '/:locale/p/firmensprachkurse'},
            es: {path: '/:locale/p/empresas'},
            fr: {path: '/:locale/p/entreprises'},
            it: {path: '/:locale/p/corporate-it'},
            pl: {path: '/:locale/p/corporate-pl'},
            pt_BR: {path: '/:locale/p/corporate-br'},
            tr: {path: '/:locale/p/corporate-tr'},
        },
        path: '/:locale/p/corporate',
    },
    direct_register_section: {
        path: '/:locale/:section/direct-register/:curriculum',
    },
    english: {
        local: {
            de: {path: '/:locale/englisch'},
            es: {path: '/:locale/ingles'},
            fr: {path: '/:locale/anglais'},
            it: {path: '/:locale/english-it'},
            pl: {path: '/:locale/english-pl'},
            pt_BR: {path: '/:locale/english-br'},
            tr: {path: '/:locale/english-tr'},
        },
        path: '/:locale/english',
    },
    error_language: {path: '/:locale/:section/error/language'},
    faq: {path: '/:locale/faq/'},
    forgot_password: {path: '/:locale/forgot-password'},
    forgot_password_successful: {path: '/:locale/forgot-password/thank-you'},
    free_trial_purchase: {path: '/:locale/free-trial-purchase/:packageId'},
    french: {
        local: {
            de: {path: '/:locale/franzoesisch'},
            es: {path: '/:locale/frances'},
            fr: {path: '/:locale/francais'},
            it: {path: '/:locale/french-it'},
            pl: {path: '/:locale/french-pl'},
            pt_BR: {path: '/:locale/french-br'},
            tr: {path: '/:locale/french-tr'},
        },
        path: '/:locale/french',
    },
    german: {
        local: {
            de: {path: '/:locale/deutsch'},
            es: {path: '/:locale/aleman'},
            fr: {path: '/:locale/allemand'},
            it: {path: '/:locale/german-it'},
            pl: {path: '/:locale/german-pl'},
            pt_BR: {path: '/:locale/german-br'},
            tr: {path: '/:locale/german-tr'},
        },
        path: '/:locale/german',
    },
    gesdinet_jwt_refresh_token: {path: '/api/token/refresh'},
    get_started: {path: '/:locale/get-started'},
    get_started_step_name: {path: '/:locale/get-started/:stepName'},
    how_it_works: {path: '/:locale/how-it-works'},
    hwi_oauth_connect_login_service: {path: '/auth/connect/login/service/:service/:key'},
    hwi_oauth_connect_registration: {path: '/auth/connect/registration/:key'},
    hwi_oauth_connect_userinfo: {path: '/auth/connect/userinfo/:key'},
    hwi_oauth_disconnect_service: {path: '/auth/disconnect/service/:service'},
    hwi_oauth_finalize_disconnect_service: {
        path: '/auth/disconnect/service/:service/finalize/:token',
    },
    imprint: {
        local: {
            de: {path: '/:locale/impressum'},
            es: {path: '/:locale/informacion'},
            fr: {path: '/:locale/informations'},
            it: {path: '/:locale/imprint-it'},
            pl: {path: '/:locale/imprint-pl'},
            pt_BR: {path: '/:locale/imprint-br'},
            tr: {path: '/:locale/imprint-tr'},
        },
        path: '/:locale/imprint',
    },
    italian: {
        local: {
            de: {path: '/:locale/italienisch'},
            es: {path: '/:locale/italiano'},
            fr: {path: '/:locale/italien'},
            it: {path: '/:locale/italian-it'},
            pl: {path: '/:locale/italian-pl'},
            pt_BR: {path: '/:locale/italian-br'},
            tr: {path: '/:locale/italian-tr'},
        },
        path: '/:locale/italian',
    },
    'lingoda.student.booking': {path: '/:locale/account/booking'},
    'lingoda.student.challenge': {path: '/:locale/account/challenges/:challengeName'},
    'lingoda.student.changeLearningLanguageStorefront': {
        path: '/:locale/account/change-learning-language-storefront/:section/:curriculum',
    },
    'lingoda.student.changeLearningLanguageSuccess': {
        path: '/:locale/account/change-learning-language-success',
    },
    'lingoda.student.classDetails': {path: '/:locale/account/class/details/:class_id'},
    'lingoda.student.classes': {path: '/:locale/account/classes'},
    'lingoda.student.courseOverview': {
        path: '/:locale/account/course-overview/:moduleName',
    },
    'lingoda.student.creditsConversion': {path: '/:locale/account/credits-conversion'},
    'lingoda.student.leadConversionFlow': {path: '/:locale/account/learn-with-lingoda'},
    'lingoda.student.lessonDetails': {path: '/:locale/account/lesson/details/:lessonId'},
    'lingoda.student.preClassChecklist': {path: '/:locale/account/class/:classId'},
    'lingoda.student.settings': {path: '/:locale/account/settings/:page'},
    'lingoda.student.store': {path: '/:locale/account/store'},
    'lingoda.student.store.accountClosed': {path: '/:locale/account/store/account-closed'},
    'lingoda.student.store.cancelCourseChange': {
        path: '/:locale/account/store/cancel-course/change/:subscription/:cancelReason',
    },
    'lingoda.student.store.cancelCoursePause': {
        path: '/:locale/account/store/cancel-course/pause/:subscription/:cancelReason',
    },
    'lingoda.student.store.cancelSurvey': {
        path: '/:locale/account/store/cancel-survey/:subscription',
    },
    'lingoda.student.store.canceledCourse': {
        path: '/:locale/account/store/canceled-course/:subscription',
    },
    'lingoda.student.store.changedCourse': {path: '/:locale/account/store/changed-course'},
    'lingoda.student.store.changedPaymentMethod': {
        path: '/:locale/account/store/changed-payment-method',
    },
    'lingoda.student.store.confirmCancel': {
        path: '/:locale/account/store/confirm-cancel/:subscription/:cancelReason',
    },
    'lingoda.student.store.creditsAdded': {path: '/:locale/account/store/credits-added'},
    'lingoda.student.store.reactivateCourse': {path: '/:locale/account/store/reactivate-course'},
    'lingoda.student.store.reactivated': {path: '/:locale/account/store/reactivated'},
    'lingoda.student.store.refusedPayment': {
        path: '/:locale/account/store/refused-payment/:subscription',
    },
    'lingoda.student.subscriptionsStore': {path: '/:locale/account/internal-store'},
    'lingoda.student.teacher': {path: '/:locale/account/teacher/:uid'},
    linguaskill_from_cambridge: {path: '/:locale/linguaskill-from-cambridge'},
    m2m_register_section: {path: '/:locale/:section/m2m-register'},
    m2m_signup_section: {path: '/:locale/:section/m2m-signup'},
    package_purchase: {path: '/:locale/package/:packageId'},
    payment_method_change_default: {path: '/api/payments/methods/change-default'},
    payment_method_create: {path: '/api/payments/methods'},
    press: {path: '/:locale/press'},
    privacy: {path: '/:locale/privacy'},
    product_order: {path: '/:locale/product-order/:productVariantId'},
    product_purchase: {
        path: '/:locale/product-purchase/:productVariantId',
    },
    referral: {path: '/:locale/referral/:code'},
    register: {path: '/:locale/register'},
    register_sprint_section: {
        path: '/:locale/:section/sprint-register/:curriculum',
    },
    register_teams_section: {path: '/:locale/:section/teams-register'},
    research: {path: '/:locale/p/research'},
    resolve_classroom_url: {path: '/api/classes/:uniqueId/room'},
    resources: {path: '/:locale/p/resources'},
    section_home: {path: '/:locale/:section'},
    social_initiate_disconnect_service: {
        path: '/:locale/social/disconnect/service/:service/initiate/:token',
    },
    social_login_connect_confirmed: {path: '/:locale/social/connect/confirmed'},
    social_login_connect_confirmed_mobile: {
        path: '/:locale/social/connect/confirmed/mobile/:token',
    },
    social_register: {path: '/:locale/social/register/:key'},
    social_register_confirm: {path: '/:locale/:section/social-register-confirm'},
    social_register_confirmed_mobile: {
        path: '/:locale/social/register/confirmed/mobile/:token',
    },
    spanish: {
        local: {
            de: {path: '/:locale/spanisch'},
            es: {path: '/:locale/espanol'},
            fr: {path: '/:locale/espagnol'},
            it: {path: '/:locale/spanish-it'},
            pl: {path: '/:locale/spanish-pl'},
            pt_BR: {path: '/:locale/spanish-br'},
            tr: {path: '/:locale/spanish-tr'},
        },
        path: '/:locale/spanish',
    },
    sprint_package_purchase: {path: '/:locale/package/sprint/:packageId'},
    sprint_signup_section: {
        path: '/:locale/:section/sprint-signup/:curriculum',
    },
    sprint_student_store_checkout: {
        path: '/:locale/account/store/sprint-checkout/:purchaseId',
    },
    student_achievements: {path: '/:locale/account/achievements'},
    student_change_subscription_failed: {
        path: '/:locale/account/store/change-subscription-failed',
    },
    student_email_verification: {path: '/:locale/account/email-verification'},
    student_exercises: {path: '/:locale/exercises/:learningUnitId'},
    student_flashcards: {path: '/:locale/flashcards/:learningUnitId'},
    student_grammar: {path: '/:locale/account/grammar/:lessonId'},
    student_grammar_item: {path: '/:locale/grammar/:grammarId'},
    student_home: {path: '/:locale/account'},
    student_homework: {path: '/:locale/account/homework/:lessonId'},
    student_lemonade: {path: '/:locale/lemonade/:id'},
    student_logout: {path: '/:locale/logout'},
    student_onboarding: {path: '/:locale/onboarding'},
    student_onboarding_booking: {path: '/:locale/onboarding/booking'},
    student_onboarding_booking_skip: {path: '/:locale/onboarding/booking/skip'},
    student_onboarding_congratulations: {path: '/:locale/onboarding/congratulations'},
    student_onboarding_level: {path: '/:locale/onboarding/level'},
    student_onboarding_level_pick: {path: '/:locale/onboarding/level/pick'},
    student_onboarding_level_placed: {path: '/:locale/onboarding/level/placed'},
    student_onboarding_level_sub: {path: '/:locale/onboarding/level/:level/:sublevel'},
    student_onboarding_survey: {path: '/:locale/onboarding/survey'},
    student_onboarding_timezone: {path: '/:locale/onboarding/timezone'},
    student_onboarding_welcome: {path: '/:locale/onboarding/welcome'},
    student_post_checkout: {path: '/:locale/post-checkout'},
    student_post_checkout_book_class: {path: '/:locale/post-checkout/book-class'},
    student_post_checkout_congratulations: {path: '/:locale/post-checkout/congratulations'},
    student_post_checkout_credits_type_selection: {
        path: '/:locale/post-checkout/credits-type-selection',
    },
    student_post_checkout_proficiency: {path: '/:locale/post-checkout/proficiency'},
    student_post_checkout_proficiency_find_your_level: {
        path: '/:locale/post-checkout/proficiency/find-your-level',
    },
    student_post_checkout_proficiency_level: {path: '/:locale/post-checkout/proficiency/level'},
    student_post_checkout_proficiency_sub_level: {
        path: '/:locale/post-checkout/proficiency/level/:level',
    },
    student_post_checkout_proficiency_summary: {
        path: '/:locale/post-checkout/proficiency/summary/:level/:sublevel',
    },
    student_post_checkout_welcome: {path: '/:locale/post-checkout/welcome'},
    student_practice: {path: '/:locale/account/practice'},
    student_practice_sequence: {path: '/:locale/lingobites/:lessonId'},
    student_profile: {path: '/:locale/account/profile'},
    student_quiz: {path: '/:locale/account/quiz/:lessonId'},
    student_quiz_next: {path: '/:locale/quiz'},
    student_store_credits_checkout: {
        path: '/:locale/account/store/credits-checkout/:purchaseId',
    },
    student_store_m2m_checkout: {
        path: '/:locale/account/store/m2m-checkout/:purchaseId',
    },
    student_store_paymentFailed: {
        path: '/:locale/account/store/payment-failed/:purchaseId',
    },
    student_store_paymentPending: {
        path: '/:locale/account/store/payment-pending/:purchaseId',
    },
    student_vocabulary: {path: '/:locale/account/vocabulary/:lessonId'},
    teacher_booking: {path: '/:locale/booking'},
    teacher_booking_pagination: {path: '/:locale/booking/:startDate'},
    teacher_class: {path: '/:locale/classes/:classId'},
    teacher_forgot_password: {path: '/:locale/teacher-forgot-password'},
    teacher_forgot_password_success: {path: '/:locale/teacher-forgot-password/thank-you'},
    teacher_home: {path: '/:locale/'},
    teacher_jwt_logout: {path: '/:locale/teacher-logout'},
    teacher_learning_material: {path: '/:locale/learning-material'},
    teacher_log_and_payments: {path: '/:locale/payments'},
    teacher_login: {path: '/:locale/teacher-login'},
    teacher_login_check: {path: '/teacher/login_check'},
    teacher_open_pdf_uid: {path: '/teacher/pdf/:uniqueId'},
    teacher_profile_new: {path: '/:locale/profile/:page'},
    teacher_reset_password: {path: '/:locale/resetting/reset/:token'},
    teacher_terms: {path: '/:locale/terms/:agreementId'},
    teacher_view_terms: {path: '/:locale/view/terms/:agreementId'},
    teams_package_purchase: {path: '/:locale/package/teams/:packageId'},
    teams_pricing: {path: '/:locale/teams/prices/'},
    teams_signup_section: {path: '/:locale/:section/teams-signup'},
    teams_store_checkout: {
        path: '/:locale/account/store/teams-checkout/:purchaseId',
    },
    terms: {path: '/:locale/terms'},
    user_resetting_reset: {path: '/:locale/resetting/reset/:token'},
    user_security_check: {path: '/login_check'},
    user_security_jwt_logout: {path: '/api/jwt-logout'},
    user_security_login: {path: '/:locale/login'},
    user_set_password_for_new_account: {path: '/:locale/password/set/:token'},
};
