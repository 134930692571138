import { createSelector } from 'reselect';
import type { TimeFormat, TimeFormatLegacy, WeekStart } from '@lingoda/dates';
import { createDate, getTimeFormatFromLegacy, startOfWeek } from '@lingoda/dates';
import studentSelector from './student';

export const studentPreferencesRawSelector = createSelector(
    studentSelector,
    (student) => student && student.preferences,
);

export const studentPreferencesSelector = createSelector(
    studentPreferencesRawSelector,
    (preferences) => {
        return preferences
            ? {
                  ...preferences,
                  timeFormat: getTimeFormatFromLegacy(
                      preferences?.timeFormat as TimeFormat | TimeFormatLegacy,
                  ),
              }
            : undefined;
    },
);

export const studentWeekStartPreference = createSelector(
    studentPreferencesSelector,
    (preferences) => preferences?.weekStart,
);

export const studentWeekStartDate = createSelector(studentWeekStartPreference, (weekStart) => {
    const today = createDate();

    return startOfWeek(today, { weekStartsOn: weekStart as WeekStart });
});
