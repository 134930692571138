import { createSelector } from 'reselect';
import { ClassType } from '@lingoda/graphql';
import studentSelector from './student';

type ClassTypeCredits = {
    [key in ClassType.Group | ClassType.Individual | 'total']: number;
};

export default createSelector(studentSelector, (student): ClassTypeCredits => {
    const groupCredits = student?.groupCredits || 0;
    const privateCredits = student?.individualCredits || 0;

    return {
        [ClassType.Group]: groupCredits,
        [ClassType.Individual]: privateCredits,
        total: groupCredits + privateCredits,
    };
});
