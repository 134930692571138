import { type Section, getTranslatedSection } from '@lingoda/sections';
import { capitalize } from '@lingoda/utils';
import { isOldPracticeMaterialsEnabled } from '@lingoda/feature-flags';
import type { Module } from './models';

export const getModuleHeading = (module: Module | undefined, section: Section | undefined) => {
    if (!module || !section) {
        return '';
    }

    if (module.cefrLevelName) {
        const language = capitalize(getTranslatedSection(section.name));

        return `${language} ${module.longName}`;
    }

    return module.longName;
};

export const isNotLegacyModule = (expandedModule: Module) => !/old/i.exec(expandedModule.code);

export const moduleIsAvailableForSelection = (module: { code: string }) => {
    return ['Open discussion'].every(
        (ignoredCode) => module.code.toLowerCase() !== ignoredCode.toLowerCase(),
    );
};

export const checkModuleLevel = (moduleName: string, level: 'a' | 'b' | 'c') => {
    return moduleName.toLowerCase().startsWith(level);
};

// 📝 Always enabled for `c` level modules, for others based on feature flag
// Old practice materials are Easygenerator and H5P exercises
export const isOldPracticeEnabled = (moduleName: string) => {
    return isOldPracticeMaterialsEnabled() || checkModuleLevel(moduleName, 'c');
};
