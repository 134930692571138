/**
 * Classroom URL names are required to start with `classroom_` prefix
 */
export interface ClassroomUrls {
    (name: 'classroom_class', params: { classUid: string }, locale?: string): string;
    (name: 'classroom_class_review', params: { classUid: string }, locale?: string): string;
    (name: 'classroom_class_onboarded', params: { classUid: string }, locale?: string): string;
    (name: 'studentApp_class_details', params: { classUid: string }, locale?: string): string;
    (name: 'teacherApp_class_details', params: { classUid: string }, locale?: string): string;
    (name: 'classroom_unauthorized', locale?: string): string;
}

export default {
    classroom_class: { path: '/:locale/class/:classUid' },
    classroom_class_review: { path: '/:locale/class/:classUid/review' },
    classroom_class_leave: { path: '/:locale/class/:classUid/leave' },
    classroom_demo_class_entry: { path: '/:locale/demo' },
    classroom_demo_class: { path: '/:locale/demo/class/:classUid' },
    classroom_demo_class_completed: { path: '/:locale/demo/class/:classUid/completed' },
    studentApp_class_details: { path: '/:locale/account/class/details/:classUid' },
    teacherApp_class_details: { path: '/:locale/classes/:classUid' },
    classroom_unauthorized: { path: '/:locale/unauthorized' },
};
