import { type TypePolicy } from '@apollo/client';
import { isOldPracticeMaterialsEnabled } from '@lingoda/feature-flags';
import { LessonType } from '@lingoda/graphql';
import { checkModuleLevel } from '@lingoda/modules';

export const LearningUnit: TypePolicy = {
    fields: {
        exercise: {
            /**
             * Should be `null` when old practice materials are disabled except for:
             * - modules with level 'c'
             * - modules with orientation lessons
             */
            read(field, options) {
                if (isOldPracticeMaterialsEnabled()) {
                    return field;
                }

                const module = options.readField('module');

                if (module && options.isReference(module)) {
                    const moduleName = options.readField('name', module);

                    if (typeof moduleName !== 'string') {
                        return null;
                    }

                    if (checkModuleLevel(moduleName, 'c')) {
                        return field;
                    }
                }

                const lessonsRef = options.readField('lessons');

                if (lessonsRef && Array.isArray(lessonsRef)) {
                    const isOrientation = lessonsRef.some((lessonRef) => {
                        if (lessonRef && options.isReference(lessonRef)) {
                            const lessonType = options.readField('type', lessonRef);

                            return lessonType === LessonType.Orientation;
                        }
                    });

                    if (isOrientation) {
                        return field;
                    }
                }

                return null;
            },
        },
    },
};
